import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import Spotlight from 'src/components/hero/Spotlight';
import ArticleLink from 'src/components/links/article/Article';

import styles from './Hero.module.scss';

const Hero = ({ content }) => (
  <section className={styles.hero}>
    <div className={styles.hero__wrapper}>
      <div className={styles.hero__main}>
        <Spotlight
          title={content.spotlight.article.title}
          publishDate={content.spotlight.article.publishDate}
          category={content.spotlight.article.feature.category}
          featureName={content.spotlight.article.feature.featureName}
          featureShortName={content.spotlight.article.feature.featureShortName}
          featureImageUrl={
            content.spotlight.article.feature.featureAvatarUrls.small
          }
          creators={content.spotlight.article.feature.creators}
          bylineOverride={content.spotlight.article.feature.bylineOverride}
        />
      </div>
      <div className={styles.hero__side}>
        <div className={styles.hero__linkContainer}>
          {content.articles.map((article, index) => (
            <div className={styles.hero__link} key={uuidv4()}>
              <div className={styles.hero__linkWrapper}>
                <ArticleLink
                  title={article.title}
                  publishDate={article.publishDate}
                  category={article.feature.category}
                  featureName={article.feature.featureName}
                  featureShortName={article.feature.featureShortName}
                  decorateCategoryColor
                  clickLocation="hero"
                  clickPosition={index + 2} // Increment to account for Spotlight
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

Hero.propTypes = {
  content: PropTypes.shape({
    spotlight: PropTypes.shape({
      article: PropTypes.shape({
        publishDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature: PropTypes.shape({
          category: PropTypes.string.isRequired,
          bylineOverride: PropTypes.string,
          featureName: PropTypes.string.isRequired,
          featureShortName: PropTypes.string.isRequired,
          featureAvatarUrls: PropTypes.shape({
            small: PropTypes.string.isRequired,
          }),
          creators: PropTypes.arrayOf(
            PropTypes.shape({
              fullName: PropTypes.string.isRequired,
            }).isRequired
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        publishDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature: PropTypes.shape({
          featureName: PropTypes.string.isRequired,
          featureShortName: PropTypes.string.isRequired,
          category: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Hero;
